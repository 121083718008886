.sidebar {
	background-color: #1e222d;

	.nav-link {
		.nav-icon {
			color: $hover_blue;
		}
	}

	.nav {
		.nav-dropdown {
			.nav-dropdown-items {
				.nav-item {
					A.nav-link {
						padding-left: 2rem;
						background: $viewber_blue;
						&.active {
							background: $active_nav_item;
						}
						&:hover {
							background: $hover_blue;
						}
					}
				}
			}
		}
	}
}
