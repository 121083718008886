.app-header {
	background-color: $viewber_blue;

	.nav-item {
		&:last-of-type {
			margin: 0 15px 0 0;
		}
	}

	.navbar-nav {
		.nav-link {
			color: white;
		}
	}
}

@media screen and (max-width: 767px) {
	.app-header .navbar-brand {
		margin-left: -150px !important;
	}
  }
