.profile-picture-dropzone {
    min-height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}

.profile-picture-uploading-spinner-container {
    position: absolute;
    font-size: 0.875rem;
    color: black;
    top: 25px;
    left: calc((100% - 4em) / 2);
}

.profile-picture-current {
    max-height: 180px;
}
.pending-profile-image {
    position: relative;
    display: table;
    .watermark {
        -webkit-transform: translate(-50%,-50%) rotate(331deg);
        -moz-transform: translate(-50%,-50%) rotate(331deg);
        -o-transform: translate(-50%,-50%) rotate(331deg);
        font-size: 1.5em;
        color: rgba(255, 5, 5, 0.17);
        position: absolute;
        font-family: "Denk One", sans-serif;
        text-transform: uppercase;
        padding-left: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(331deg);
        z-index: 9;
    }
    img {
        opacity: 0.7;
    }
}
