.page-title {
    font-size: 2.1875rem;
    font-weight: 500;
    color: #2f353a;
    width: 100%;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;

    &:before {
        content: '';
        width: 5px;
        height: 40px;
        background-color: #acb5bc;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0 15px 0 0;
    }

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #acb5bc;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0 0 0 15px;
    }
}

@media only screen and (max-width:480px) {
    .page-title {
        font-size: 1.875rem;
        margin-bottom: 1rem;

        white-space: normal;

        &:before {
            background: none;
            margin: 0;
        }
    }
}
