// If you want to override variables do it here
@import "variables";

// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// Import React Toastify styling
@import '~react-toastify/dist/ReactToastify.min.css';

// Import React DateTime styling
@import 'react-datetime';

// Import Nprogress styling
@import '~nprogress/nprogress.css';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
