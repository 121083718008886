div.notification-container {
    padding: 10px;
    border-radius: 4px;
    background: #fff;
    line-height: 1.5;
    position: relative;
    overflow: hidden;

    .notification-content {
        .notification-icon {
            position: absolute;
            line-height: 24px;
            margin-left: 4px;

            &.notification-icon-danger {
                color: #f86c6b;
            }

            &.notification-icon-primary {
                color: #20a8d8;
            }

            &.notification-icon-success {
                color: #4dbd74;
            }

            &.notification-icon-warning {
                color: #ffc107;
            }
        }

        .notification-title {
            font-size: 16px;
            margin-left: 48px;
            /*margin-bottom: 4px;*/
            margin-bottom: 8px;
            padding-right: 24px;
            color: rgba(0,0,0,.85);
            line-height: 24px;
            display: inline-block;
        }

        .notification-message {
            margin-left: 48px;
            color: rgba(0,0,0,.85);
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100%;
    }
}
