.card-header-action {
    &.btn-outline-danger {
        background-color: transparent;
        color: #f86c6b;

        &:hover {
            background-color: #f86c6b;
            color: #ffffff;
        }
    }

    &.btn-outline-primary {
        background-color: transparent;
        color: #20a8d8;

        &:hover {
            background-color: #20a8d8;
            color: #ffffff;
        }
    }

    &.btn-outline-success {
        background-color: transparent;
        color: #4dbd74;

        &:hover {
            background-color: #4dbd74;
            color: #ffffff;
        }
    }

    &.btn-outline-warning {
        background-color: transparent;
        color: #ffc107;

        &:hover {
            background-color: #ffc107;
            color: #23282c;
        }
    }
}
