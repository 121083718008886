.btn-auth {
	@extend .btn-primary;
	font-weight: 600;
	font-size: 0.75rem;
	border-radius: 0.5rem;
	border: none;
	padding: 0.75rem 1rem;
	transition: all 0.2s ease-in-out;
}

.btn-auth-blue {
	@extend .btn-auth;
	background-color: $viewber_blue;

	&:hover {
		background-color: lighten($viewber_blue, 10%);
	}
}

.btn-auth-light-blue {
	@extend .btn-auth;
	background-color: $viewber_light_blue;
	color: $viewber_blue;

	&:hover {
		background-color: darken($viewber_light_blue, 10%);
		color: $viewber_blue;
	}
}

.btn-ghost {
	@extend .btn-auth;
	background-color: transparent;
	border: 1px solid $hover_blue;
	color: $viewber_blue;

	&:hover {
		background-color: $hover_blue;
		border: 1px solid transparent;
		color: $turquoise;
	}
}
